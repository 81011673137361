.buttonConfirm {
    border: 0;
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    background:
        linear-gradient(136deg, rgb(242, 113, 33) 0%, rgb(233, 64, 87) 50%, rgb(138, 35, 135) 100%);
    font-size: 1rem;
    font-family: "Open Sans";
    font-weight: 400;
    line-height: 1.5;
    margin: 0,
}

.buttonConfirm:hover {
    background: linear-gradient(136deg, rgb(222, 93, 13) 0%, rgb(213, 44, 67) 50%, rgb(118, 15, 115) 100%);
}

.buttonConfirm:disabled,
.buttonConfirm[disabled] {
    border: 1px solid #999999;
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
}
