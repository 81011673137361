@keyframes go-back {
    from {
        transform: translateY(300px);
    }

    to {
        transform: translateY(0);
    }
}

#divScan {
    background: black;
    width: 200px;
    height: 4px;
    animation: go-back 1s infinite alternate;
    position: fixed;
}
