/* @font-face {
    font-family: 'Auro Black';
    src: local('Auro Black'), url(./fonts/Auro-Black.otf) format('opentype');
  } */

@font-face {
  font-family: 'OpenSans Regular';
  src: url("fonts/OpenSans/OpenSans-Regular.ttf") format('ttf');
}

@font-face {
  font-family: 'OpenSans ExtraBold';
  src: url("fonts/OpenSans/OpenSans-ExtraBold.ttf") format('ttf');
}
